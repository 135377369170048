body {
  margin: 0;
  font-size: 0.75rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.d-flex {
  display: flex;
}
.Polaris-Icon {
  margin: 0;
}

.Polaris-DatePicker__Header {
  position: unset;
}
.Polaris-DatePicker__MonthLayout {
  display: flex;
}
.center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.disable {
  pointer-events: none;
  opacity: 0.9;
}
.ml-8 {
  margin-left: 0.5rem;
}
.ml-16 {
  margin-left: 1rem;
}
.mt-16 {
  margin-top: 1rem;
}
.mt-32 {
  margin-top: 2rem;
}
.mt-4 {
  margin-top: 0.25rem;
}
.mb-4 {
  margin-bottom: 0.25rem;
}
.mt-8 {
  margin-top: 0.5rem;
}
.mb-8 {
  margin-bottom: 0.5rem;
}
.mb-16 {
  margin-bottom: 1rem;
}
.mr-8 {
  margin-right: 0.5rem;
}
.pd-16 {
  padding: 1rem;
}
.pd-32 {
  padding: 2rem;
}
.space-vertical-8 {
  margin: 0.5rem 0;
}
select {
  padding-left: 1rem;
}
.Polaris-Frame__NavigationDismiss {
  display: none;
}
.Polaris-TextField input {
  font-size: 13px;
}
.Polaris-Select .Polaris-Select__Content {
  font-size: 13px;
}
.Polaris-TextField__CharacterCount {
  font-size: 13px;
}
.Polaris-Tabs__ButtonWrapper .Polaris-Tabs{
  width: 100%;
}
